import { useCallback, useMemo, useState, useEffect } from 'react';

import { useRouter } from 'next/router';
import { useUser } from '@hooks/user';
import { useAuth } from '@hooks/auth';
import { useTracking } from '@hooks/tracking';

import ShareArea from '@components/core/Share/ShareArea';
import {
  HeaderPlatform,
  MenuOption,
  RatingModal,
} from '@startse/react-components';
import {
  HomeIcon,
  ArticlesIcon,
  VideoIcon,
  PodcastIcon,
  TicketIcon,
  ProgramIcon,
  WebSerieIcon,
  CourseIcon,
} from '../Icons';

const TRACKING = '?utm_source=platform';

const Header: React.FC = ({ children }) => {
  const { user, setShowEditProfile } = useUser();
  const { setShowModalLogin, logout } = useAuth();
  const { track } = useTracking();
  const [evaluatingSpeaker, setEvaluatingSpeaker] = useState('');
  const [isPush, setIsPush] = useState(false);
  const [scrollPages, setScrollPages] = useState({
    '/': 0,
    '/artigos': 0,
    '/aulas': 0,
  });
  const userData = {
    id: user?._id,
    photoUrl: user?.profileImageURL,
    name: `${user?.firstName} ${user?.lastName}`,
  };

  const router = useRouter();
  const searchTerm = useMemo(
    () => (router?.query?.q as string) || '',
    [router],
  );

  useEffect(() => {
    if (
      (router.pathname === '/' ||
        router.pathname === '/artigos' ||
        router.pathname === '/aulas') &&
      isPush
    ) {
      window.scrollTo({ top: scrollPages[router.pathname] });
      setIsPush(false);
    }
    // eslint-disable-next-line
  }, [router.pathname]);
  const handleMenu = useCallback(
    (path: string) => {
      setScrollPages({ ...scrollPages, [router.pathname]: window.scrollY });
      setIsPush(true);
      router.push(path);

      if (path === '/eventos') {
        track('Click menu Events', {
          namePage: window.location.href,
        });
      }
    },
    [router, scrollPages, track],
  );

  const menuOptions = [
    { label: 'Inicio', icon: <HomeIcon />, path: '/' },
    { label: 'Artigos', icon: <ArticlesIcon />, path: '/artigos' },
    { label: 'Aulas', icon: <VideoIcon />, path: '/aulas' },
    {
      label: 'Cursos',
      icon: <CourseIcon />,
      path: `${process.env.NEXT_PUBLIC_LMS}${TRACKING}`,
    },
    { label: 'Eventos', icon: <TicketIcon />, path: '/eventos' },
    {
      label: 'Web Séries',
      icon: <WebSerieIcon />,
      path: '/webseries',
      showSoon: true,
    },
    {
      label: 'Programas',
      icon: <ProgramIcon />,
      path: '/programas',
      showSoon: true,
    },
    {
      label: 'Podcasts',
      icon: <PodcastIcon />,
      path: '/podcasts',
      showSoon: true,
    },
  ];

  const getIsSelect = (path: string) => {
    return path === router?.pathname?.split('/[')[0];
  };

  return (
    <HeaderPlatform
      onPressLogo={() => router.push('/')}
      logoutOnClick={logout}
      noUserMenuClicked={() => setShowModalLogin(true)}
      openProfileOnClick={() => setShowEditProfile(true)}
      user={user && user._id ? userData : undefined}
      onNotificationClick={(link, action) => {
        if (link) document.location.href = link;
        if (action?.type === 'rateEventSpeaker' && action?.data?.activity)
          setEvaluatingSpeaker(action.data.activity);
      }}
      menuOptions={menuOptions.map(
        item =>
          ({
            ...item,
            onClick: () => handleMenu(item.path),
            isSelected: getIsSelect(item.path),
          } as MenuOption),
      )}
      onSearchFullPage={value => router.push(`/busca?q=${value}`)}
      onSearchItemClick={item => router.push(item.path)}
      searchDefaultTerm={searchTerm}
    >
      {user?._id && (
        <RatingModal
          closeModalControlled={() => setEvaluatingSpeaker(null)}
          contentId={evaluatingSpeaker}
          showModalControlled={!!evaluatingSpeaker}
          type="eventActivity"
          userId={user._id}
          ShareComponent={<ShareArea />}
        />
      )}
      {children}
    </HeaderPlatform>
  );
};

export default Header;
